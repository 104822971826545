import { NextPage } from 'next';
import { signIn } from 'next-auth/react';
import { FormEventHandler, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';

const ModalContent = styled('div')(() => ({
  backgroundColor: '#fefefe',
  position: 'absolute',
  width: ' 400px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '20px',
  border: '1px solid #888',
  borderRadius: '4px',
}));
const Form = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
}));

const SignIn: NextPage = (): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    openModal();
  }, []);

  const [userInfo, setUserInfo] = useState({ username: '', password: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    // validate your userinfo
    e.preventDefault();

    const res = await signIn('credentials', {
      username: userInfo.username,
      password: userInfo.password,
      redirect: false,
    });

    if (res?.status === 200 && res?.error === null) {
      setTimeout(() => {
        window.location.href = '/';
      }, 500);
    } else {
      setErrorMessage(res?.error || 'Invalid credentials');
    }
  };
  return (
    <div className="sign-in-form">
      <div>
        <Modal open={isModalOpen}>
          <ModalContent>
            <h4 style={{ textAlign: 'center' }}>Enter credentials</h4>
            <Form onSubmit={handleSubmit}>
              <TextField
                label="Username"
                variant="outlined"
                value={userInfo?.username}
                onChange={(e) => {
                  setErrorMessage('');
                  setUserInfo({ ...userInfo, username: e.target.value });
                }}
                required
              />
              <TextField
                label="Password"
                variant="outlined"
                type="password"
                value={userInfo?.password}
                onChange={(e) => {
                  setErrorMessage('');
                  setUserInfo({ ...userInfo, password: e.target.value });
                }}
                required
              />
              {errorMessage && (
                <p style={{ color: '#E20000', textAlign: 'center' }}>
                  Please enter valid credentials
                </p>
              )}
              <Button variant="contained" color="primary" type="submit">
                Login
              </Button>
            </Form>
          </ModalContent>
        </Modal>
      </div>
    </div>
  );
};

export default SignIn;
